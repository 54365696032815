import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { useConfig } from '~/resources';
import { Post } from '~/resources/models';
import Title from '~/components/typography/Title';
import Paragraph from '~/components/typography/Paragraph';
import { PostType } from '~/resources/PostType';
import { generateDateStringRuLocale } from '~/utils/datetime';
import Image from '../Image';
import Topic from '../Topic';
import c from './PreviewPost.sass';
import Rubric from '../Rubric';

type Props = {
    post: Post;
    showRubricLabel?: boolean;
}

const PreviewPost: FunctionComponent<Props> = ({
  post, showRubricLabel = true,
}) => {
  const {
    topic, published_at, title, description,
    image_preview, preview_mode = 'preview', slug, rubric,
  } = post;
  const { data: config } = useConfig();
  const imageConfig = config ? config.images : {};

  const isGuide = post.type === PostType.GUIDE;

  const urlPost = `/post/${slug}`;
  const urlTopic = isGuide ? '/guides' : (topic && `/topic/${post.topic.slug}`);
  const urlRubric = rubric && `/rubric/${rubric.slug}`;

  const topicTitle = post.type === PostType.GUIDE ? 'Гайды Reminder' : topic?.title;
  const topicSubtitle = generateDateStringRuLocale(new Date(+published_at * 1000));

  return (
    <div className={`${c.container} ${c[`container_${preview_mode}`]}`}>
      <div className={c.content}>
        {(topicTitle) && (
          <div className={c.top}>
            <Topic
              title={topicTitle}
              subtitle={topicSubtitle}
              href={urlTopic}
              colorOrange={!!post.partner_material}
              isGuide={isGuide}
            />
            {showRubricLabel && rubric && <Rubric name={rubric.name} href={urlRubric} />}
          </div>
        )}
        <Link to={urlPost} className={c.title}>
          <Title level={3} className={c.title}>{title}</Title >
        </Link>
        {description && (
          <div className={c.description}>
            <Paragraph>{description}</Paragraph>
          </div>
        )}
      </div>
      <div className={c.imageWrap}>
        {description && (
          <div className={`${c.description} ${c.description_mob}`}>
            <Paragraph>{description}</Paragraph>
          </div>
        )}
        {image_preview && (
          <Link to={urlPost}>
            <Image
              {...image_preview}
              mode={preview_mode}
              className={c[`place-${preview_mode}`]}
              imageConfig={imageConfig}
              contentEditable={undefined}
              alt={image_preview.alt || title}
            />
          </Link>
        )}
      </div>
    </div>
  );
};

PreviewPost.displayName = 'Post';

export default PreviewPost;
